import request from "@/Utils/api"

// 题目列表
export function topicList (data) {
  return request({
    url: "api/v1_0_0.question",
    method: "post",
    data
  })
}

// 添加题目选项
export function options (data) {
  return request({
    url: "api/v1_0_0.question/options",
    method: "post",
    cache:true,
    data
  })
}

// 添加题目
export function createTopic (data) {
  return request({
    url: "api/v1_0_0.question/create",
    method: "post",
    data
  })
}
//更新题单
export function updateTopic (data) {
  return request({
    url: "api/v1_0_0.question_list/update",
    method: "post",
    data
  })
}
export function remove (data) {
  return request({
    url: "api/v1_0_0.question/remove",
    method: "post",
    data
  })
}
// 题目详情
export function detail (data) {
  return request({
    url: "api/v1_0_0.question/detail",
    method: "post",
    data
  })
}

// 提交记录
export function refer_record (data) {
  return request({
    url: "api/v1_0_0.question/refer_record",
    method: "post",
    data
  })
}
// 题目讨论列表
export function comment_list (data) {
  return request({
    url: "api/v1_0_0.question/comment_list",
    method: "post",
    data
  })
}
// 答题答案信息
export function question_log_answer (data) {
  return request({
    url: "api/v1_0_0.user/question_log_answer",
    method: "post",
    data
  })
}
// 单个题目 提交答题信息
export function questionAnswer (data) {
  return request({
    url: "api/v1_0_0.question/answer",
    method: "post",
    data
  })
}
// 题目讨论提交内容
export function comment_submit (data) {
  return request({
    url: "api/v1_0_0.question/comment_submit",
    method: "post",
    data
  })
}
// 题目讨论提交内容
export function recommend (data) {
  return request({
    url: "api/v1_0_0.question/recommend",
    method: "post",
    data
  })
}
// 题目讨论提交内容
export function get_import_data (data) {
  return request({
    url: "api/v1_0_0.question/get_import_data",
    method: "post",
    data
  })
}
// 题目讨论提交内容
export function editTopic (data) {
  return request({
    url: "api/v1_0_0.question/edit",
    method: "post",
    data
  })
}
export function copyTD (data) {
  return request({
    url: "api/v1_0_0.question_list/copy",
    method: "post",
    data
  })
}
// 获取单个提交记录
export function d_record (data) {
  return request({
    url: "api/v1_0_0.question/d_record",
    method: "post",
    data
  })
}

// 答题信息结果集
export function answer_result (data) {
  return request({
    url: "api/v1_0_0.question/answer_result",
    method: "post",
    data
  })
}
// 答题记录列表
export function get_log_list (data) {
  return request({
    url: "api/v1_0_0.question/get_log_list",
    method: "post",
    data
  })
}


// 获取单个提交记录是否结束获取分数
export function d_wrecord (data) {
  return request({
    url: "api/v2_0_0.question/d_wrecord",
    method: "post",
    data:typeof data === 'object' ? data : {log_id:parseInt(data)}
  })
}
// 答题信息结果集
// export function answer_result_d (data) {
//   return request({
//     url: "api/v1_0_0.question/answer_result",
//     method: "post",
//     data
//   })
// }
// 推荐题目 显示状态
export function recommendField(data){
  return request({
      url: "/xapi/question.question/updateField",
      method: "POST",
      data:data,
    });
}
// 推荐题单 显示状态
export function recommendFieldProblem(data){
  return request({
      url: "/xapi/question.quest_list/updateField",
      method: "POST",
      data:data,
    });
}

// // 题目
// export function updateField(data){
//   return request({
//       url: "/xapi/question.question/updateField",
//       method: "get",
//       params:data,
//     });
// }





