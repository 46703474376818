<template>
  <div>
    <el-row :gutter="20" >
      <el-col :span="4">
        <el-select v-model="where.type" placeholder="请选择结果" clearable size="small">
          <el-option v-for="item in options.case_judge_reault.list" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" v-show="questionInfo.type+'' === '1'">
        <el-select v-model="where.language" placeholder="请选择语言" clearable size="small">
          <el-option v-for="item in options.language.list" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" >
        <el-select v-model="where.is_repeat" placeholder="是否去重" clearable size="small">
          <el-option   label="去重" :value="1"></el-option>
          <el-option   label="不去重" :value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="请输入姓名" v-model="where.nickname" clearable size="small">
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="$refs.table.reset(where)">搜 索</el-button>
      </el-col>
    </el-row>
    <com-table style="margin-top: 10px;" ref="table"
               :columns="questionInfo.type===1?columnsAll[1]:columnsAll[2]"
               :data="{url:'api/v1_0_0.question/refer_record',params:{id:question_id||$attrs.question_id}}"
               :header-cell-class-name="'blueColor'"
               :page-options="{layout:'prev, pager, next'}"
    >
      <template slot="result" slot-scope="{row}">
        <div class="submit-result">
          <div class="btn-result btnMsg" :style="{background:row.remark.color}">{{ row.remark.label }}</div>
          <div class="btn-result" :style="{background:row.remark.color}" v-if="!(row.remark.label==='Wait')">{{ row.remark.value }}</div>
          <el-button plain size="mini" :disabled="jloading" type="primary" @click="$refs.table.reload(where)" style="padding: 7px 7px;"
                     v-if="row.remark && row.remark.label==='Wait'" icon="el-icon-refresh"
          >点击刷新</el-button>
        </div>
      </template>
      <template slot="answer" slot-scope="{row}">
          <span v-if="row.answer&&row.answer.length">
             <span v-for="(item,index) in row.answer" :key="index">{{item}}<br/></span>
          </span>
      </template>
    <template slot="operation" slot-scope="{row}">
      <div style="display: inline-flex;justify-content: space-between;">
        <el-button type="primary" class="details_btn" size="mini"
                   :disabled="!(editMethod && userInfo.id===row.user_id)"
                   @click="editMethod(row)"
                   v-if="questionInfo.type+'' === '1'">编辑</el-button>
        <el-button type="primary" class="details_btn" size="mini"
                   @click="$handleRoute({log_id:row.id,id:questionInfo.id} , 'topicRecord' , '_blank')"
                   :disabled="!(userType===2||userInfo.id===row.user_id)"
        >查看详情</el-button>
      </div>
    </template>
    </com-table>
  </div>
</template>

<script>
import { options } from "@/api/topic.js"
export default {
  props: {
    question_id:0,
    questionInfo:{
      type:Object,
      default: () => { return {}; },
    },
    editMethod:{
      type:Function,
      default: null,
    }
  },
  data(){
    return {
      jloading:false,
      where:{},
      columnsAll:{
        1:[
          // { prop: "id", label: "提交编号", width: '80',align:'center' },
          { prop: "nickname", label: "用户", width:"120",align:'center' },
          { prop: "remark", label: "结果",slot: "result", width:"200",align:'center' },
          { prop: "run_space", label: "运行空间",align:'center' },
          { prop: "run_time", label: "运行时间",align:'center', },
          { prop: "lang", label: "语言",align:'center', },
          { prop: "run_length", label: "代码长度",align:'center', },
          { prop: "_create_time", label: "提交时间", width:"160",align:'center' },
          { fixed:'right', label: "操作", slot: 'operation',align:'center' ,width:125},
        ],
        2:[
          // { prop: "id", label: "提交编号", width: '80',align:'center' },
          { prop: "nickname", label: "用户", width:"120",align:'center' },
          { prop: "remark.label", label: "结果",align:'center', width:"200", },
          { prop: "grade", label: "分数",align:'center', },
          { prop: "answer", label: "我提交的答案",slot: 'answer',align:'center' },
          { prop: "_create_time", label: "提交时间", width:"160",align:'center' },
          { fixed:'right',label: "操作", slot: 'operation',align:'center' ,width:125},
        ]
      },
      options: { case_judge_reault: { list: [] }, language: { list: [] } },
    }
  },
  methods:{
    routeUpdate(){
      options({}).then((res) => {
        this.options = res.data
      })
    },
  }
}
</script>

<style scoped lang="less">
::v-deep{
  .blueColor {
    color: #000;
    background: #f7f7f7!important;
  }
  .details_btn{
    padding: 0 4px!important;
    border-radius: 2px;
    height: 25px;
  }
  .details_btn.is-disabled,
  .details_btn.is-disabled:active,
  .details_btn.is-disabled:focus,
  .details_btn.is-disabled:hover{
    background: #00957ea3 !important;
    color: #ffffffc7 !important;
  }
  .submit-result {
    display: flex;
    justify-content: center;
    .btnMsg {
      margin-right: 0.5rem;
    }
    .btn-result {
      border-right: 6px;
      color: #fff;
      padding: 0 6px;
    }
  }
}
</style>